import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"

function ContactPage() {
  return (
    <Layout>
      <SEO title="Kontakt - ZSP Nidek" />
      <div>
        <h1>Kontakt</h1>
        <section>
          <p>Zespół Szkolno- Przedszkolny w Nidku</p>

          <p>im. Mikołaja Kopernika</p>
          <address>
            <p>Nidek, ul. Św. Judy Tadeusza 2</p>

            <p>34-122 Wieprz</p>
          </address>
        </section>
        <section>
          <p>Dyrektor szkoły: mgr Ewa Snażyk- Płonka</p>
        </section>
        <section>
          <p>
            <a href="tel:0338755811">tel. (0-33 prefiks) 875-58-11</a>
          </p>

          <p>
            <a href="mailto:sekretariat@zspnidek.wieprz.pl">
              e-mail: sekretariat@zspnidek.wieprz.pl
            </a>
          </p>
        </section>
      </div>
    </Layout>
  )
}

export default ContactPage
